<template>
  <section id="check-price">
    <v-img
      :height="minHeight"
      :src="require('@/assets/main.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center text-center"
          height="100%"          
          width="100%"
        >
          <base-heading size="text-h3" title="Оформление СБКТС и ЭПТС<br />на любое Транспортное средство" />
          <base-subheading size="text-h4" mobile-size="title" weight="regular" tag="h2" title="возможно, у нас самое выгодное предложение в городе" />          

          <!-- <div            
            class="d-flex flex-wrap align-center"
          > -->
            <base-btn min-width="25%" class="mt-10" @click="showOrderForm">
              Узнать стоимость
            </base-btn>
            
          <!--   -->
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import ShowOrder from '@/mixins/show-order'

  export default {
    name: 'SectionCheckPrice',

    mixins: [ShowOrder],

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        //const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        const height = '100vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    // methods: { 
    //   gotoOrderForm() {        
    //     if (this.$route.hash == '#order') { 
    //       this.$router.replace({ hash: '' })
    //     }
    //     this.$router.replace({ hash: 'order' })
    //   },
    // },
  }
</script>
